@import "../../../../assets/scss/screen.scss";
@import "../../../../assets/scss/colors.module.scss";
@import "../../../../assets/scss/typography.scss";


.eargo-care-container{
    display: flex;
    flex-direction: row;
    margin: 15px 75px 30px;
    border-radius: 40px;
    background: white;
    padding: 24px 40px 15px 40px;
    @include tablet {
        border-radius: 16px;
        margin: 20px;
        padding: 24px 16px 24px 16px;

    }
    .eargo-care-layout {
        font-family: 'Inter', sans-serif;
        font-style: normal;
        width: 100%;
        .eargo-care-product-info-container{
            display: flex;
            flex-direction: row;
            gap:40px;
            padding: 24px 0;
            @include tablet {
                flex-direction: column;
            }
            @include mobile {
                gap:24px;
            }
        }
        .eargo-care-logo {
            padding-bottom: 8px;
            border-bottom: 2px solid $grey-6;
            height: 25px;
            svg {
                height: 100%;
                width: auto;
            }
        }
        .eargo-care-divider{
            height: 2px;
            background-color: $grey-6;
            border: none;
        }
        .eargo-care-product-info {
            display: flex;
            flex-direction: column;
            max-width: 250px;
            word-wrap: break-word;
            gap: 18px;
            @include mobile {
                flex-direction: row;
                justify-content: space-between;
                max-width: unset;
            }
            .eargo-product-details {
                display: flex;
                flex-direction: column;
                gap: 4px;
            }
        }
        .product-info-body{
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            font-style: normal;
            @include mobile {
              font-size: 16px;
              line-height: 22.4px;
            }
    
        }
        .eargo-care-includes {
            display: flex;
            flex-direction: column;
            gap: 4px;
            .features{
                display: flex;
                flex-direction: column;
                gap:4px;
                .feature-list{
                    display: flex;
                    flex-direction: row;
                    gap: 8px;
                    align-items: center;
                    .feature-icon{
                        display: flex;
                    }
                }
            }
        }
        .product-info-body-small {
            font-size: 16px;
            font-weight: 400;
            line-height: 25.6px;
            font-style: normal;
            @include mobile {
              font-size: 14px;
              line-height: 19.6px;
            }
          }
        .add-eargo-care-button .button_text .sentence_case {
            text-transform: none;  
        }
        .learn-more {
            color: #3278B8;
            cursor: pointer;
        }
        .divider {
            height: 2px;
            background-color: $grey-6;
            border: none;
            margin: 0;
        }
    }
}
