@import "../../../../assets/scss/screen.scss";
@import "../../../../assets/scss/colors.module.scss";
@import "../../../../assets/scss/typography.scss";

.doc_row_container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  text-align: center;
  margin : 25px 35px 25px;
  .left_section {
    display: flex;
    margin-right: auto;
  }
  .extension{
    margin-left: 0px;
    padding : 16.5px 0px;
    width : 60px;
    height: 60px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 148%;
    text-transform: uppercase;
    color: $grey-2;
    box-sizing: border-box;
    border: 1px solid #999999;
    border-radius: 60px;
    @include mobile{
      min-width: 60px;
      min-height: 60px;
    }
  }
  .normal_view{
    width : 100%;
    display: flex;
    @include mobile{
      display: none;
    }
  }
  .mobile_view{
    display: none;
    @include mobile{
      display: flex;
    }
  }
  .file_details_container{
    margin-left : 24px;
    text-align: start;
    color: $grey-1;
  }
  .heading{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 132%;
    color: $grey-1;
    margin-bottom: 9px;
    @include mobile{
      font-size: 20px;
      line-height: 136%;
    }
  }
  .filename{
    width: 500px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 148%;
    color: $grey-1;
    margin-bottom: 9px;
    @include tablet{
      width: 180px;
      font-size: 16px;
      line-height: 152%;
      margin-bottom: 16px;
    }
    @include mobile{
      width: 180px;
      font-size: 16px;
      line-height: 152%;
      margin-bottom: 16px;
    }
  }

  .right_section{
    margin-left : auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .date{
    width : 200px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 148%;
    color: $grey-1;
    @include mobile{
      width: auto;
      margin-bottom: 9px;
    }
  }
  .download_delete_container{
    display: flex;
    flex-direction: row;
  }
  .download{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 148%;
    color: $dark-blue-4;
    cursor: pointer;
  }
  .delete{
    margin-left : 24px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 148%;
    color: $error-red;
    cursor: pointer;
  }
}

.seperator{
  margin : 0px 35px;
  border: 1px solid #D9D9D9;
}

.delete_confirmation_container{
  margin : 0px 35px 25px;
  display : flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: rgba(128, 157, 190, 0.1);
  border: 1px solid #D9D9D9;
  border-radius: 10px;
  height: 96px;
  @include mobile{
    height: 200px;
  }
  .left_rectangle{
    width: 15px;
    height: 96px;
    background: #809DBE;
    border-radius: 10px 0px 0px 10px;
    @include mobile{
      height: 200px;
    }
  }
  .center_text_container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include mobile{
      flex-direction: column;
      justify-content: start;
    }
    .sure{
      margin-right: 24px;
      text-transform: uppercase;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 148%;
      color: $grey-1;
      @include mobile{
        margin: 23px auto;
        font-size: 16px;
        line-height: 152%;
      }
    }
    .button{
      margin-right: 30px;
      @include mobile{
        margin: 0px auto;
      }
    }
    .no{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      color: #FF5E2B;
      cursor: pointer;
      @include mobile{
        margin: 33px auto auto;
      }
    }
  }
  .keep-document-button{
    background: #FFFFFF;
    color: #FF5E2B;
    width: 216px;
    height: 56px;
  }
}