@import "../../../../assets/scss/screen.scss";
@import "../../../../assets/scss/colors.module.scss";
@import "../../../../assets/scss/typography.scss";

.purchase-receipt-container {
  position: relative;
  .receipt-box-container {
    background: $white;
    box-sizing: border-box;
    border: 1px solid $grey-6;
    border-radius: 20px;
    margin: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .full_width{
      padding: 40px 16px;
      display : flex;
      flex-direction: column;
      align-items: center;
      gap : 40px;
      width: 866px;
      @include tablet{
        width: 325px;
        height: auto;
        padding: 15px 0px 15px;
      }

    }
    .title-section {
      display: flex;
      flex-direction: column;
      gap: 8px;
      align-items: center;
      text-align: center;
      .header{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 148%;
        text-transform: uppercase;
        color: $grey-1;
        @include tablet{
          font-size: 16px;
          line-height: 152%;
        }
      }
      .sub-header{
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 148%;  
        color: $grey-1;
        @include tablet{
          font-size: 16px;
          line-height: 152%;
        }
      }
    }
    .document-upload-section {
      display: flex;
      flex-direction: row;
      .hidden-input{
        display: none;
      }
      .form-container {
        display: flex;
        flex-direction: row;
        gap: 120px;
        @include tablet{
          flex-direction: column;
          gap: 40px;
        }
      }
    }
    .date-section {
      display: flex;
      flex-direction: column;
      gap:24px;
      align-items: center;
    }
    .error-message{
      color: $red;
      margin-top: 10px;
      font-weight: 300;
      font-size: 16px;
      line-height: 18px;
      
    }
    label.primary-input-label {
      height: 56px;
      width: 216px;
      padding-left: 10px
    }
    .file-upload-section {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    label.file-upload {
        display: flex;
        flex-direction: column;
        cursor: pointer;
    }
    .file-name{
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    .upload-icon {
      margin: 0px auto 32px;
      width: 33px;
      height: 33px;
    }
    .success_image{
      width: 100px;
      height: 100px;
      margin: 25px auto;
      @include mobile {
        width: 70px;
        height: 70px;
      }
      @include tablet {
        width: 70px;
        height: 70px;
      }
    }
    .image_name_holder {
      margin : 0px auto 9px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 148%;
      color: $grey-1;
      width: 180px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include mobile {
        font-size: 16px;
        line-height: 152%;
      }
      @include tablet {
        font-size: 16px;
        line-height: 152%;
      }
    }
    .image_size_holder {
      margin : 0px auto;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 176%;
      color: $grey-1;
    }

    .upload-description{
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 148%;
      color: $grey-1;
    }
  }
  .sizeError{
    transform: translate(0%, 40%);
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    left: 20px;
    right: 20px;
  }
  
  .box_blur{
    filter: blur(5px)
  }
}